const GithubIcon = () => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6411 31.5C11.6411 31.5 10.436 26.7 12.8463 24.3C12.8463 24.3 8.0257 24.3 4.41028 20.7C0.794861 17.1 2 9.9 4.41028 7.5C3.20514 3.9 5.61542 1.5 5.61542 1.5C5.61542 1.5 9.23083 1.5 11.6411 3.9C14.0514 2.7 20.0771 2.7 22.4874 3.9C24.8976 1.5 28.5131 1.5 28.5131 1.5C28.5131 1.5 30.9233 3.9 29.7182 7.5C32.1285 9.9 33.3336 17.1 29.7182 20.7C26.1028 24.3 21.2822 24.3 21.2822 24.3C23.6925 26.7 22.4874 31.5 22.4874 31.5M10.436 30.3C6.82056 31.5 3.20514 29.1 2 27.9"
        stroke="#F05454"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default GithubIcon;

const NotFoundTextIcon = () => {
  return (
    <svg
      width="571"
      height="251"
      viewBox="0 0 571 251"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="notFoundTextIcon"
    >
      <path
        d="M10 10V102.251C10 108.368 13.2251 114.234 18.9658 118.559C24.7065 122.884 32.4925 125.314 40.6111 125.314H132.444M132.444 10V240.628M438.556 10V102.251C438.556 108.368 441.781 114.234 447.521 118.559C453.262 122.884 461.048 125.314 469.167 125.314H561M561 10V240.628M224.278 33.0628V217.565C224.278 223.682 227.503 229.548 233.244 233.873C238.984 238.199 246.77 240.628 254.889 240.628H316.111C324.23 240.628 332.016 238.199 337.756 233.873C343.497 229.548 346.722 223.682 346.722 217.565V33.0628C346.722 26.9462 343.497 21.0801 337.756 16.755C332.016 12.4298 324.23 10 316.111 10H254.889C246.77 10 238.984 12.4298 233.244 16.755C227.503 21.0801 224.278 26.9462 224.278 33.0628Z"
        stroke-width="20"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default NotFoundTextIcon;

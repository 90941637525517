const ExitIcon = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.608426 0.608426C0.800802 0.415563 1.02934 0.262547 1.28094 0.158143C1.53254 0.0537393 1.80227 0 2.07468 0C2.34708 0 2.61681 0.0537393 2.86842 0.158143C3.12002 0.262547 3.34856 0.415563 3.54093 0.608426L14.5005 11.5722L25.4602 0.608426C25.6527 0.415875 25.8813 0.263135 26.1329 0.158927C26.3845 0.0547195 26.6541 0.0010843 26.9264 0.0010843C27.1987 0.0010843 27.4684 0.0547195 27.7199 0.158927C27.9715 0.263135 28.2001 0.415875 28.3927 0.608426C28.5852 0.800977 28.738 1.02957 28.8422 1.28115C28.9464 1.53273 29 1.80237 29 2.07468C29 2.34699 28.9464 2.61663 28.8422 2.86821C28.738 3.11979 28.5852 3.34838 28.3927 3.54093L17.4289 14.5005L28.3927 25.4602C28.5852 25.6527 28.738 25.8813 28.8422 26.1329C28.9464 26.3845 29 26.6541 29 26.9264C29 27.1987 28.9464 27.4684 28.8422 27.7199C28.738 27.9715 28.5852 28.2001 28.3927 28.3927C28.2001 28.5852 27.9715 28.738 27.7199 28.8422C27.4684 28.9464 27.1987 29 26.9264 29C26.6541 29 26.3845 28.9464 26.1329 28.8422C25.8813 28.738 25.6527 28.5852 25.4602 28.3927L14.5005 17.4289L3.54093 28.3927C3.34838 28.5852 3.11979 28.738 2.86821 28.8422C2.61663 28.9464 2.34699 29 2.07468 29C1.80237 29 1.53273 28.9464 1.28115 28.8422C1.02957 28.738 0.800977 28.5852 0.608426 28.3927C0.415875 28.2001 0.263135 27.9715 0.158927 27.7199C0.0547195 27.4684 0.0010843 27.1987 0.0010843 26.9264C0.0010843 26.6541 0.0547195 26.3845 0.158927 26.1329C0.263135 25.8813 0.415875 25.6527 0.608426 25.4602L11.5722 14.5005L0.608426 3.54093C0.415563 3.34856 0.262547 3.12002 0.158143 2.86842C0.0537393 2.61681 0 2.34708 0 2.07468C0 1.80227 0.0537393 1.53254 0.158143 1.28094C0.262547 1.02934 0.415563 0.800802 0.608426 0.608426Z"
        fill="#F05454"
      />
    </svg>
  );
};

export default ExitIcon;

const LinkedInIcon = () => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.1435 10.9535H16.7145V13.7285C17.517 12.1325 19.575 10.6985 22.6665 10.6985C28.593 10.6985 30 13.8755 30 19.7045V30.5H24V21.032C24 17.7125 23.1975 15.8405 21.1545 15.8405C18.321 15.8405 17.1435 17.858 17.1435 21.0305V30.5H11.1435V10.9535ZM0.855 30.245H6.855V10.6985H0.855V30.245ZM7.7145 4.325C7.71472 4.82791 7.61498 5.32585 7.42108 5.78988C7.22718 6.25391 6.94298 6.67477 6.585 7.028C5.8596 7.74894 4.87772 8.15248 3.855 8.15C2.83408 8.14931 1.85447 7.7468 1.128 7.0295C0.771317 6.67507 0.488079 6.25374 0.294515 5.78965C0.100952 5.32556 0.000868698 4.82784 0 4.325C0 3.3095 0.405 2.3375 1.1295 1.6205C1.85533 0.902238 2.83536 0.499551 3.8565 0.5C4.8795 0.5 5.8605 0.9035 6.585 1.6205C7.308 2.3375 7.7145 3.3095 7.7145 4.325Z"
        fill="#F05454"
      />
    </svg>
  );
};
export default LinkedInIcon;
